import React, { Component } from 'react';

import ODAWrapper from './ODAWrapper';
// import Sentry from './SentryWrapper';

import { getOdaConfig } from '../../utils/constants';

const overwrite = ['componentDidMount'];

export const BaseComponentHOC = WrappedComponent => {
  return class BaseComponentHOC extends Component {
    constructor(props) {
      super(props);
      overwrite.map(item => {
        if (this.__proto__.__proto__[item]) {
          this[item] = (...args) => {
            this.__proto__.__proto__[item].bind(this)(...args);
            if (this.__proto__[item]) this.__proto__[item].bind(this)(...args);
          };
        }
        return null;
      });
      // Sentry.init(getSpecificConfig('sentry'));
      ODAWrapper.init(getOdaConfig());
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
      const hasStateChanged = !this.shallowEqualObjects(nextState, this.state);
      const hasPropsChanged = !this.shallowEqualObjects(nextProps, this.props);
      return hasPropsChanged || hasStateChanged;
    }

    // componentDidCatch(error, errorInfo) {
    //   Sentry.sendError({ error, errorInfo });
    // }

    sendPageView(data) {
      ODAWrapper.sendPageView(data);
    }

    shallowEqualObjects(objA, objB) {
      if (objA === objB) {
        return true;
      }
      const aKeys = Object.keys(objA);
      const len = aKeys.length;
      for (let i = 0; i < len; i++) {
        const key = aKeys[i];
        if (objA[key] !== objB[key]) {
          return false;
        }
      }
      return true;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default BaseComponentHOC;
