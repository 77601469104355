/**
 * constants.js defines constants for PL usage
 * Constants are also exported for potential usage in site as well
 */

export const TEXT_NULL_VALUE = '--';

export const TIME_ZONE_ET = 'America/New_York';

export const DECIMAL_DIGITS = 7;

export const detectEnvByDomain = (() => {
  const {
    location: { hostname = 'adr.com' },
  } = window;
  const envs = {
    localhost: 'dev',
    '127.0.0.1': 'dev',
    'wd-appsd701.dev.local':'dev',
    'adr-a2.markitqa.com': 'acc',
    'adr.wsodqa.com': 'acc',
    'jpm-adr-a2.markitqa.com': 'acc',
    'jpm-adr.markitqa.com': 'acc',
  };
  return envs[hostname] || 'production';
})();

export const detectedEnv = detectEnvByDomain;

export const defaultApiURL =
  'https://dev-api.markitdigital.com/jpmadr-public/v1';

export const settingsEnv = {
  dev: {
    sentry: {
      scriptSource: 'https://cdn.ravenjs.com/3.19.1/raven.min.js',
      config: 'http://c02d2ff9340f4344b7987373b4d57f27@sentry.dev.local/17',
      disabled: true, // to avoid noise on sentry
    },
    staticAPI: defaultApiURL
  },
  acc: {
    sentry: {
      scriptSource: 'https://cdn.ravenjs.com/3.19.1/raven.min.js',
      config:
        'https://35c728492efc4f74af5f32b2db72c872@logging-api.markitqa.com/13',
    },
    staticAPI:'//qa-api.markitdigital.com/jpmadr-public/v1'
  },
  production: {
    staticAPI:'//api.markitdigital.com/jpmadr-public/v1',
  },
};

export const getSpecificConfig = type => {
  let config = {};
  if (settingsEnv[detectedEnv] && settingsEnv[detectedEnv][type])
    config = settingsEnv[detectedEnv][type];
  return config;
};

export const detectOdaEnvByDomain = (() => {
  const {
    location: { 
      hostname,
      pathname
     }
  } = window;

  let env = hostname

  if (
    (hostname === 'adr.com' || hostname === 'www.adr.com') &&
    pathname.indexOf('/issuerservices') === 0
  ) {
    env = 'adr.com/issuerservices'
  }

  const envs = {
    localhost: 'dev',
    '127.0.0.1': 'dev',
    'wd-appsd701.dev.local':'dev',
    'adr-a2.markitqa.com': 'acc',
    'adr.wsodqa.com': 'acc',
    'jpm-adr-a2.markitqa.com': 'acc',
    'jpm-adr.markitqa.com': 'acc',
    'adr.com': 'prodAdr',
    'www.adr.com': 'prodAdr',
    'adr.com/issuerservices': 'prodAdrIssuerservices',
    'jpm-adr.markitondemand.com': 'prod',
  };
  return envs[env] || 'dev';
})();

export const detectedOdaEnv = detectOdaEnvByDomain;

export const odaSettingsEnv = {
  dev: {
    domain: `oda.markitqa.com`,
    reportingId: '112406',
  },
  acc: {
    domain: `oda.markitqa.com`,
    reportingId: '112406',
  },
  prod: {
    domain: `oda.markitondemand.com`,
    reportingId: '112406',
  },
  prodAdr: {
    domain: `oda.markitondemand.com`,
    reportingId: '630042',
  },
  prodAdrIssuerservices: {
    domain: `oda.markitondemand.com`,
    reportingId: '436678',
  }
};

export const getOdaConfig = () => odaSettingsEnv[detectedOdaEnv] || odaSettingsEnv['dev']

export const URLbyDomain = getSpecificConfig('staticAPI');

export const TABSCUSIP = [
  'otherCorporateActions',
  'booksOpenClosed',
  'ownership',
  'allEvents',
  'dividends',
  'tradingVolume',
];

export const labelBooksDropdown = {
  UpcomingClose: 'Upcoming Close',
  UpcomingOpen: 'Upcoming Open',
  AllStatuses: 'All Statuses',
  Close: 'Closed',
};

export const labelSponsorshipDropdown = {
  All: 'All Sponsored / Unsponsored',
};

export const labelListingsDropdown = {
  All: 'All Listings',
};
export const optionsBooksDropdown = {
  Closed: [1, 4],
  Open: [2, 3],
  'All Statuses': [1, 2, 3, 4],
};

export const optionsSponsorshipDropdown = {
  'All Sponsored / Unsponsored': ['S', 'U'],
};
export const optionsListingsDropdown = {
  'All Listings': ['No', 'Yes'],
};

export const pathEndpoint = '/api/endpoints';
export const apiRetries = 3;
export const notAuthenticatedStatus = [401,403];
export const stringForCancelAPI = 'Cancelled request';

export const EXPORT_OPTION_CURRENT_VIEW = 'Current View';
export const EXPORT_OPTION_FULL_UNIVERSE = 'Full Universe';
