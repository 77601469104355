import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import IHSComponent from './components/baseComponent/BaseComponent';

class ScrollToTop extends IHSComponent {
  constructor() {
    super();
    this.logPage = this.logPage.bind(this);
  }
  componentDidMount() {
    const { location } = this.props;
    this.logPage(location);
  }
  logPage(location) {
    const { pathname } = location;
    if (pathname && pathname.split) {
      const splitPathname = pathname.split('/');
      const name = splitPathname.pop(),
        category = splitPathname.pop(),
        label = splitPathname.pop();
      this.sendPageView({ name, category, label });
    }
  }

  componentDidUpdate({ location }) {
    const { location: newLocation } = this.props;

    if (newLocation !== location) {
      window.scrollTo(0, 0);
      this.logPage(newLocation);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.element, // sometimes is null
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(ScrollToTop);
