import '../../../styles/components/PrivacyPolicy.scss';
import React from 'react';

const PrivacyPolicy = () => (
  <div className="jpmprivacypolicy">
    <div>
      <div>
        <h3>J.P. Morgan Depositary Receipts Team</h3>
        <p>
          For over 200 years, respecting and protecting individual client
          privacy has been vital to our business. By sharing our Privacy
          Principles, we trust that our individual clients will better
          understand how we keep client information private and secure while
          using it to provide services and products. Other privacy principles or
          policies may apply to clients of certain J.P. Morgan businesses in
          certain jurisdictions, such as United States Private Banking clients,
          Asset Management clients in Luxembourg or individual clients in Hong
          Kong. Similarly, J.P. Morgan clients who receive information from or
          transact business with J.P. Morgan through the Internet are covered by
          the terms and conditions and any privacy notices posted on the Web
          sites they visit.
        </p>
        <p>
          We may disclose and transfer any information that is provided through
          this Web site to: any company within the J.P. Morgan group, its
          affiliates, agents or information providers; to any other person or
          entity with a client‎’s consent; or if we have a right or duty to
          disclose or are permitted or compelled to so disclose such information
          by law. We may also transmit, transfer or process such information to,
          or through, any country in the world, as we deem necessary or
          appropriate (including to countries outside the EEA).
        </p>
        <h4>We protect client information</h4>
        <p>
          We take our responsibility to protect the privacy and confidentiality
          of any client information very seriously. We maintain physical,
          electronic and procedural safeguards that comply with applicable legal
          standards to store and secure client information from unauthorized
          access and use, alteration and destruction. Our own policies and
          procedures have been developed to protect the confidentiality of
          client information and to help achieve compliance with relevant rules
          and regulations. We hold our employees accountable for complying with
          those policies, procedures, rules and regulations.
        </p>
        <h4>
          We endeavor to collect, use and maintain client information only for
          appropriate purposes.
        </h4>
        <p>
          We limit the collection, use and retention of client information to
          what we reasonably believe will help us deliver superior service, to
          administer our business, manage our risks, market our services and
          products, and to meet applicable laws and regulations.
        </p>
        <h4>
          We share client information with affiliates and with others when we
          believe it will enhance the services and products we can provide to
          clients or for legal and routine business reasons.
        </h4>
        <ul>
          <li>
            We share client information among our affiliates and business units
            when we believe it will enhance the services we can provide to
            clients, but only in circumstances where such sharing conforms to
            law, any applicable confidentiality agreements, our policies and
            practices, or where a client has consented to such sharing.
          </li>
          <li>
            We may disclose information we have about clients as permitted by
            law. For example, we may share information with regulatory
            authorities and law enforcement officials who have jurisdiction over
            us or if we are required to do so by applicable law and to provide
            information to protect against fraud.
          </li>
          <li>
            From time to time, we enter into agreements with other companies to
            provide services to us or make services and products available to
            clients. Under these agreements, the companies may receive
            information about clients, but they must safeguard this information
            and they may only use it for those purposes that we specify.
          </li>
        </ul>
        <h4>
          We provide clients with relevant and appropriate choices regarding the
          sharing of information with our affiliates and others.
        </h4>
        <ul>
          <li>
            We will give clients choices regarding the sharing of information
            with affiliates and third parties in accordance with applicable law
            and their agreements with us.
          </li>
        </ul>
        <h4>
          We comply with the USA Patriot Act, Section 326. Please read this
          important information about procedures for opening a new account.
        </h4>
        <ul>
          <li>
            To help the government fight the funding of terrorism and money
            laundering activities, Federal law requires all financial
            institutions to obtain, verify, and record information that
            identifies each person who opens an account.
          </li>
          <li>
            What this means for you: When you open an account, we will ask for
            your name, address, date of birth (for individuals), and other
            information that will allow us to identify you. We may also ask to
            see your driver‎’s license or other identifying documents.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
