import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route , Switch  } from 'react-router-dom';

import packageJson from '../package.json';

import './styles/components/css-module/componentes.scss';
import './styles/fonts/fonts.scss';
import Authenticated from './issuerservices/AppAuthenticated';
import IframeForTests from './components/removibleIframe/IframeForTests';
import Public from './AppPublic';
import * as UrlMethods from './utils/urlMethods';
import { KEY_LOCAL_STORAGE_SHOW_DEBUG_INFO } from './utils/appConstants';

class App extends Component {
  constructor () {
    super();
    this.initShowDebugInfo();
    this.initGoogleAnalytics();
    console.info(`App version: ${packageJson.version}`)
  }

  componentDidMount () {
    document.addEventListener(
      'touchstart',
      function addtouchclass() {
        // first time user touches the screen
        document.documentElement.classList.add('can-touch'); // add "can-touch" class to document root using classList API
        document.removeEventListener('touchstart', addtouchclass, false); // de-register touchstart event
      },
      false
    );
  }

  initGoogleAnalytics () {
    const MD = window.MD || {};
    const tagManagerArgs = {
      gtmId: 'GTM-TB63S4D',
      auth: MD.gtmAuth || '',
      preview: MD.gtmPreview || ''
    };

    TagManager.initialize(tagManagerArgs);
  }

  initShowDebugInfo () {
    const showDebugInfo = UrlMethods.isDebugOn() ? 'true' : 'false';
    localStorage.setItem(KEY_LOCAL_STORAGE_SHOW_DEBUG_INFO, showDebugInfo);
  }

  render () {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/issuerservices*" component={Authenticated} />
          <Route exact path="/iframe" component={IframeForTests} />
          <Route component={Public} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
