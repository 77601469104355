class ODAWrapper {
  constructor() {
    if (!ODAWrapper.instance) {
      this.loading = false;
      this.callbacksQueue = [];
      this.domain = `oda.markitqa.com`;
      this.reportingId = '112406';
      ODAWrapper.instance = this;
    }
    return ODAWrapper.instance;
  }
  exist() {
    return window._waq;
  }
  tryload() {
    return new Promise(resolve => {
      if (!this.exist() && !this.loading) {
        this.callbacksQueue.push(resolve);
        this.init();
      } else if (this.loading) {
        this.callbacksQueue.push(resolve);
      } else resolve();
    });
  }
  init(options = {}) {
    if (this.exist() || this.loading) return null;
    if (options.disabled) {
      this.disabled = true;
      return null;
    }
    const { domain = this.domain, reportingId = this.reportingId } = options;
    window._waq = window._waq || [];
    this.loading = true;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//${domain}/js/wa.js`;
    script.onload = this.loaded.bind(this);
    window._waq.push(['_setup', { reportingid: reportingId, domain }]);
    return document.head.appendChild(script);
  }
  loaded() {
    this.loading = false;
    this.callbacksQueue.map(callback => callback());
  }
  sendMetric(data) {
    if (this.disabled) {
      return null;
    }
    return this.tryload().then(() => this.trysendMetric(data));
  }
  sendPageView(data) {
    const {
      name = 'ADR-name',
      category = 'ADR-category',
      label = 'ADR-label',
    } = data;
    this.sendMetricType('page', { name, category, label });
  }
  sendMetricType(type, obj) {
    const arrayToSend = [];
    switch (type) {
      case 'user':
        arrayToSend.push('_setUser');
        break;
      case 'page':
        arrayToSend.push('_trackPageView');
        break;
      case 'action':
        arrayToSend.push('_trackAction');
        break;
      case 'entity':
        arrayToSend.push('_trackEntity');
        break;
      case 'links':
        arrayToSend.push('_trackLinks');
        break;
      case 'downloads':
        arrayToSend.push('_trackDownloads');
        break;
    }
    arrayToSend.push(obj);
    this.sendMetric(arrayToSend);
  }
  trysendMetric(data) {
    window._waq.push(data);
  }
}

export default new ODAWrapper();
